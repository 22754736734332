<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-2"
        :name="ownerName"
        :text="
          $t('emails.ownerBookingConfirmation.intro', locale, {
            clientName: clientName,
          })
        "
      />

      <!-- PICTURE && ACCOMMODATION INFO -->
      <accommodation-info
        :accommodation="accommodation"
        booking-status="CONFIRMED"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- CHECK-IN AND CHECKOUT TIMES -->
      <checkin-checkout
        :checkin="checkin"
        :checkout="checkout"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- LOCALIZATOR -->
      <localizator :locale="locale" />

      <b-divider size="lg" />

      <!-- CLIENT -->
      <client :locale="locale" :show-phone="includePhone" />
      <b-form-checkbox v-model="includePhone" class="mt-1">
        <span class="cursor-pointer no-select"> Incloure nº de telèfon? </span>
      </b-form-checkbox>

      <b-divider size="lg" />

      <!-- GUESTS -->
      <guests
        :alert-text="$t('emails.common.pendingGuestsAmountAlert', locale)"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- PRICE DETAILS -->
      <price-details :locale="locale" />

      <b-divider size="lg" />

      <!-- OWNER CHARGABLE SERVICES -->
      <template v-if="ownerUnchargableServices.length">
        <owner-onsite-chargable-services
          :locale="locale"
          :services="ownerUnchargableServices"
        />

        <b-divider size="lg" />
      </template>

      <!-- SECURITY DEPOSIT -->
      <template v-if="hasSecurityDeposit">
        <security-deposit
          :alert-text="
            hasChargableSecurityDeposit
              ? $t('emails.common.ownerSecurityDepositInfoAlert', locale)
              : $t('emails.common.ownerChargesSecurityDepositAlert', locale)
          "
          :locale="locale"
        />

        <b-divider size="lg" v-if="intranetLink" />
      </template>

      <!-- INTRANET BUTTON -->
      <b-button
        v-if="intranetLink"
        block
        variant="primary"
        :href="intranetLink"
        target="blank"
      >
        {{ $t("emails.common.intranetAccessButton", locale) }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BFormCheckbox } from "bootstrap-vue";
import {
  getAccommodationTypeName,
  getBookingServiceClientTotalOrUnitPrice,
  getBookingServiceOwnerTotalPrice,
  getBookingServiceOwnerTotalPriceText,
  getBookingServiceOwnerUnitPriceText,
  getGuestsText,
  getNightsText,
  getPaymentFrequencyUnit,
  getServiceName,
} from "@/utils/methods";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import AccommodationInfo from "@/views/bookings/booking/components/mail/AccommodationInfo.vue";
import CheckinCheckout from "@/views/bookings/booking/components/mail/CheckinCheckout.vue";
import Localizator from "@/views/bookings/booking/components/mail/Localizator.vue";
import Client from "@/views/bookings/booking/components/mail/Client.vue";
import Guests from "@/views/bookings/booking/components/mail/Guests.vue";
import PriceDetails from "@/views/bookings/booking/components/mail/OwnerPriceDetails.vue";
import SecurityDeposit from "@/views/bookings/booking/components/mail/SecurityDeposit.vue";
import OwnerOnsiteChargableServices from "@/views/bookings/booking/components/mail/OwnerOnsiteChargableServices.vue";
import { uncalculableFrequencies } from "@/utils/consts";
import {
  AGENCY_OWNERS_INTRANET_LOGIN,
  AGENCY_OWNERS_INTRANET_REGISTER,
} from "@appConfig";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDivider,
    Intro,
    AccommodationInfo,
    CheckinCheckout,
    Localizator,
    Client,
    Guests,
    PriceDetails,
    SecurityDeposit,
    OwnerOnsiteChargableServices,
    BFormCheckbox,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
      includePhone: false,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationPicture() {
      return this.accommodation?.coverPicture || null;
    },
    accommodationType() {
      if (!this.accommodation) return null;
      return getAccommodationTypeName(this.accommodation.type, this.locale);
    },
    accommodationLocation() {
      if (!this.accommodation?.location) return null;
      const location = [];
      if (this.accommodation.location.city)
        location.push(this.accommodation.location.city);
      if (this.accommodation.location.region)
        location.push(this.accommodation.location.region);
      if (!location.length) return null;
      return location.join(", ");
    },
    owner() {
      return this.$store.getters["booking/owner"];
    },
    ownerName() {
      return this.owner?.firstName || null;
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    clientDetailsProvided() {
      return this.contract?.clientDetailsProvided || false;
    },
    guestsText() {
      const { adults = 0, children = 0, babies = 0 } = this.booking || {};
      return (
        getGuestsText({ adults, children, babies }, this.locale) ||
        this.$t("Pendent")
      );
    },
    checkin() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkin,
        this.locale,
        formatting
      );
    },
    checkout() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkout,
        this.locale,
        formatting
      );
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    clientName() {
      return this.client?.firstName || null;
    },
    clientFullName() {
      return this.client?.fullName || null;
    },
    clientPhone() {
      return this.client?.phone || null;
    },
    averageRatePerNight() {
      return this.$store.getters["booking/averageOwnerRatePerNight"];
    },
    nights() {
      return this.booking?.nights || null;
    },
    ownerUnchargableServices() {
      return this.$store.getters["booking/ownerUnchargableServices"];
    },
    onsitePayments() {
      if (!this.ownerUnchargableServices.length) return [];
      return this.ownerUnchargableServices.map((bookingService) => ({
        name: getServiceName(bookingService.service, this.locale),
        price: getBookingServiceClientTotalOrUnitPrice(
          bookingService,
          this.nights
        ),
        unit:
          this.uncalculablePaymentFrequencyUnit(
            bookingService.paymentFrequency
          ) || "",
      }));
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    subject() {
      return `${this.$t(
        "emails.ownerBookingConfirmation.subject",
        this.locale,
        {
          localizator: this.booking?.localizator || null,
          accommodationName: this.accommodation?.name || null,
        }
      )}`;
    },
    intranetLink() {
      if (!this.owner) {
        return null;
      }

      if (!this.owner?.user) {
        return `${AGENCY_OWNERS_INTRANET_REGISTER}/${this.owner.uuid}?l=${this.locale}`;
      }

      return `${AGENCY_OWNERS_INTRANET_LOGIN}?l=${this.locale}`;
    },
    accommodationPrice() {
      return this.$store.getters["booking/ownerSubtotal"];
    },
    accommodationVatPrice() {
      const ownerVatPercentage = this.contract?.ownerVatPercentage || null;

      if (!ownerVatPercentage) {
        return 0;
      }

      return this.accommodationPrice * (ownerVatPercentage / 100);
    },
    accommodationIrpfPrice() {
      const ownerIrpfPercentage = this.contract?.ownerIrpfPercentage || null;

      if (!ownerIrpfPercentage) {
        return 0;
      }

      return this.accommodationPrice * (ownerIrpfPercentage / 100);
    },
    accommodationPriceWithVat() {
      return (
        this.accommodationPrice +
        this.accommodationVatPrice -
        this.accommodationIrpfPrice
      );
    },
    extras() {
      return this.$store.getters["booking/ownerChargableServices"].filter(
        ({ service }) => {
          const { code } = service || {};
          return code !== "TOURIST_TAX";
        }
      );
    },
    extrasPrice() {
      return this.extras.reduce((acc, { costPrice = 0 }) => acc + costPrice, 0);
    },
    contentExtras() {
      if (!this.extras?.length) {
        return [];
      }

      return this.extras.map((bookingService) => ({
        name: this.getExtraName(bookingService),
        price: this.getExtraPrice(bookingService),
      }));
    },
    hasChargableTouristTax() {
      return this.$store.getters["booking/hasChargableTouristTax"];
    },
    touristTaxChargableCostPrice() {
      return this.$store.getters["booking/touristTaxChargableCostPrice"];
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
    includePhone(include) {
      if (include) this.content.clientPhone = this.clientPhone;
      else this.content.clientPhone = null;
    },
  },
  created() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        ownerEmail: this.owner?.email || null,
        ownerSecondaryEmail: this.owner?.secondaryEmail || null,
        ownerName: this.ownerName || null,
        clientName: this.clientName || null,
        clientFullName: this.clientFullName || null,
        introHello: `Hola ${this.ownerName || null}`,
        introText: this.$t(
          "emails.ownerBookingConfirmation.intro",
          this.locale,
          {
            clientName: this.clientName,
            accommodationName: this.accommodation?.name || null,
          }
        ),
        accommodationPicture: this.accommodationPicture?.url || null,
        accommodationDescription: this.accommodationType,
        accommodationName: this.accommodation?.name || null,
        accommodationLocation: this.accommodationLocation,
        checkin: this.checkin,
        checkout: this.checkout,
        localizator: this.booking?.localizator || null,
        clientPhone: null,
        guestsText: this.guestsText,
        guestsAlertText: this.$t(
          "emails.common.pendingGuestsAmountAlert",
          this.locale
        ),
        nightsText: getNightsText(this.nights, this.locale),
        averageRatePerNight: this.averageRatePerNight,
        accommodationPrice: this.accommodationPrice,
        accommodationVatPrice: this.accommodationVatPrice,
        accommodationIrpfPrice: this.accommodationIrpfPrice,
        accommodationPriceWithVat: this.accommodationPriceWithVat,
        accommodationPaymentAlertText: this.$t(
          "emails.ownerBookingConfirmation.priceDetails.accommodationPaymentAlert",
          this.locale,
          { amount: formatCurrency(this.accommodationPriceWithVat) }
        ),
        extras: this.contentExtras,
        extrasPrice: this.extrasPrice,
        extrasPaymentAlertText: this.$t(
          "emails.ownerBookingConfirmation.priceDetails.extrasPaymentAlert",
          this.locale,
          { amount: formatCurrency(this.extrasPrice) }
        ),
        hasChargableTouristTax: this.hasChargableTouristTax,
        touristTaxChargableCostPrice: this.touristTaxChargableCostPrice,
        touristTaxPaymentAlertText: this.$t(
          "emails.ownerBookingConfirmation.priceDetails.touristTaxPaymentAlert",
          this.locale
        ),
        onsitePayments: this.onsitePayments,
        securityDeposit: this.securityDepositPrice,
        securityDespositAlertText: this.hasChargableSecurityDeposit
          ? this.$t("emails.common.ownerSecurityDepositInfoAlert", this.locale)
          : this.$t(
              "emails.common.ownerChargesSecurityDepositAlert",
              this.locale
            ),
        intranetLink: this.intranetLink,
      };
      this.includePhone = this.clientDetailsProvided;
    },
    uncalculablePaymentFrequencyUnit(code) {
      if (!uncalculableFrequencies.includes(code)) return null;
      return getPaymentFrequencyUnit(code, this.locale);
    },
    getExtraName(bookingService) {
      if (!bookingService) {
        return null;
      }

      const { service } = bookingService || {};
      const serviceName = getServiceName(service, this.locale);

      if (!serviceName) {
        return null;
      }

      const unitPriceText = getBookingServiceOwnerUnitPriceText(
        bookingService,
        this.locale
      );

      const totalPriceText = getBookingServiceOwnerTotalPriceText(
        bookingService,
        this.nights,
        this.locale
      );

      return unitPriceText && unitPriceText !== totalPriceText
        ? `${serviceName}: ${unitPriceText}`
        : serviceName;
    },
    getExtraPrice(bookingService) {
      return getBookingServiceOwnerTotalPrice(bookingService, this.nights);
    },
  },
};
</script>
