<template>
  <div>
    <h4 class="mb-1">
      {{ $t(title || "Client", locale) }}
    </h4>
    <div v-if="clientName">
      {{ clientName }}
      <span v-if="clientPhone && showPhone" class="mt-1">
        ({{ clientPhone }})
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    showPhone: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    client() {
      return this.$store.getters["booking/client"];
    },
    clientName() {
      return this.client?.fullName || null;
    },
    clientPhone() {
      return this.client?.phone || null;
    },
  },
};
</script>
